import SkeletonListChat from './SkeletonListChat';
import SkeletonOnlineUserAgent from './SkeletonOnlineUserAgent';
import SkeletonInputChat from './SkeletonInputChat';
import SkeletonHeaderChat from './SkeletonHeaderChat';

const index = {
  SkeletonListChat,
  SkeletonOnlineUserAgent,
  SkeletonInputChat,
  SkeletonHeaderChat,
};

export default index;
