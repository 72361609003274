import { io } from 'socket.io-client';
import ClientListens from './Clients/ClientListens';
const { REACT_APP_SERVICE_WEB_SOCKET } = process.env;

const Socket = new io(REACT_APP_SERVICE_WEB_SOCKET, {
  withCredentials: true,
  autoConnect: false,
  allowEIO3: false,
});

ClientListens(Socket);

export default Socket;
